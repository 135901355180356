

















































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class CharterPartyHelp extends Vue {
  tab = null;
  curveTableHeaders = [
    {
      text: "Ship Speed [kn]",
      value: "shipSpeed",
      align: "center",
      divider: true,
    },
    {
      text: "Fuel Consumption Ballast [ton/day]",
      value: "FCBallast",
      align: "center",
      divider: true,
    },
    {
      text: "Fuel Consumption Laden [ton/day]",
      value: "FCLaden",
      align: "center",
      divider: true,
    },
  ];
  curveTableItems = [
    {
      shipSpeed: "0",
      FCBallast: "10",
      FCLaden: "10",
    },
    {
      shipSpeed: "14",
      FCBallast: "60",
      FCLaden: "65",
    },
    {
      shipSpeed: "18",
      FCBallast: "110",
      FCLaden: "115",
    },
    {
      shipSpeed: "20",
      FCBallast: "140",
      FCLaden: "150",
    },
  ];
}
