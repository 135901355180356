import { render, staticRenderFns } from "./CharterParty.vue?vue&type=template&id=6d673fbe&scoped=true&"
import script from "./CharterParty.vue?vue&type=script&lang=ts&"
export * from "./CharterParty.vue?vue&type=script&lang=ts&"
import style0 from "./CharterParty.vue?vue&type=style&index=0&id=6d673fbe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d673fbe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VDataTable,VTab,VTabItem,VTabs,VTabsItems,VToolbar,VToolbarTitle})
